import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { FLOW_TYPES } from '../../context/registerContext/registerContext.type'
import { RegisterFlow, RegisterIntro } from '../../components/views/layout'
import { Input } from '../../components/common/form/fields'
import { Button, Seo } from '../../components/common'
import { useAccountValidate, useForm, useRegister } from '../../hooks'
import { Paths } from '../../constants/structure'

import { registerFields } from '../../common/utils/register'
import { step1Schema } from '../../common/utils/register'

import * as styles from './register.module.scss'

const COMPANY_TELEPHONE = process.env.APP_TELEPHONE

const REDIRECTS = {
   [FLOW_TYPES.TRIAL]: Paths.REGISTER_TRIAL,
   [FLOW_TYPES.UPGRADE]: Paths.REGISTER_VALIDATE,
   [FLOW_TYPES.EXISTING]: Paths.REGISTER_EXISTING_USER,
}

const RegistrationPage = () => {
   const { formState, update, reset } = useRegister()
   const { data, flowType, isLoading, validate } = useAccountValidate()
   const { onSubmit, validationErrors } = useForm(step1Schema, (values) => {
      if (values) {
         update({
            ...formState,
            email: values?.email,
            confirmEmail: values?.confirmEmail,
         })
         validate(values?.email)
      }
   })

   useEffect(() => {
      // need to reset register context to clear upgrade form
      reset()
   }, [])

   useEffect(() => {
      if (flowType) {
         handleFlowType()
      }
   }, [flowType])

   const handleFlowType = () => {
      update({
         ...formState,
         company: data.CompanyName,
         flowType,
         roleName: data.RoleName,
      })
      navigate(REDIRECTS[flowType])
   }

   return (
      <RegisterFlow title="Step 1">
         <Seo title="Register - Step 1" />
         <RegisterIntro>
            If you have trouble registering please call {COMPANY_TELEPHONE} and
            press 2 for support, and we will help set you up.
         </RegisterIntro>

         <form noValidate onSubmit={onSubmit}>
            <Input
               id={registerFields.EMAIL}
               label="Email"
               required
               error={validationErrors[registerFields.EMAIL]}
            />

            <input
               className={styles.hiddenInput}
               id={registerFields.CONFIRM_EMAIL}
               name={registerFields.CONFIRM_EMAIL}
            />
            <Button loading={isLoading}>Next</Button>
         </form>
      </RegisterFlow>
   )
}

export default RegistrationPage
